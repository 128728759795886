import React from 'react';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import ImageButton from '../components/ImageButton'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Stack } from '@mui/material';

function Navigationbar({title}) {

    const handleTwitter = () => {
        console.log("Open....")
        window.open('https://x.com/PjeaBihar', '_blank');
    }

    const handleMail = () => {
        window.open('mailto:contact@pjeabihar.com', '_blank');
    }

    const handleFacebook = () => {
        //TODO:
    }

    return (
        <AppBar position="fixed" sx={{ background: '#fff' }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <img src='https://res.cloudinary.com/greensyntax-co-in/image/upload/v1706824096/logo.jpg' style={{ height: '45px', width: '45px', margin: '8px' }} />
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            display: { sm: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 900,
                            fontSize: '1rem',
                            color: '#34495e',
                            textDecoration: 'none',
                            margin: '8px',
                            flexGrow: 1
                        }}
                    >
                        {title}
                    </Typography>
                    <Stack direction="row" spacing={0} edge="end">
                        <ImageButton imageURL="https://res.cloudinary.com/greensyntax-co-in/image/upload/v1706931820/twitter.png" onClick={handleTwitter} />
                        <ImageButton imageURL="https://res.cloudinary.com/greensyntax-co-in/image/upload/v1706931947/gmail.png" onClick={handleMail} />
                        <ImageButton imageURL="https://res.cloudinary.com/greensyntax-co-in/image/upload/v1706932020/whatsapp.png" onClick={handleFacebook} />
                    </Stack>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Navigationbar