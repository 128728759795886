import './App.css';
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles';
import Home from './pages/Home';
import Listing from './pages/Listing';
import Detail from './pages/Detail'
import NotFound from './pages/NotFound'
import theme from './utils/theme';

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
              <Route exact path="/" Component={Home}></Route>
              <Route path="/home" Component={Home}></Route>
              <Route path="/posts" Component={Listing}></Route>
              <Route path="/posts/:postId" Component={Detail}></Route>
              <Route path="/notFound" Component={NotFound}></Route>
            </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
