import React from 'react';
import IconButton from '@mui/material/IconButton';

const ImageButton = ({imageURL, onClick}) => {
  return (
    <IconButton onClick={onClick}>
      <img
        src={imageURL}
        style={{ width: '24px', height: '24px' }}
      />
    </IconButton>
  );
};

export default ImageButton;