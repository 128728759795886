import React from 'react'
import { Paper, Button, Typography } from '@mui/material'
import Carousel from 'react-material-ui-carousel'

function ImageCarouselView({ items }) {
    return (
        <>
            <Carousel sx={{ padding: '8px', height: '45%' }}>
                {
                    items.map((item, i) => (
                        <Paper elevation={0}>
                            <img style={{ height: '45%', width: '100%' }} src={item.imagePath} />
                            <Typography align='center'>{item.title}</Typography>
                        </Paper>
                    ))
                }
            </Carousel>
        </>
    )
}

export default ImageCarouselView