import { Divider } from '@mui/material'
import React from 'react'

function Header({text}) {
  return (
    <>
    {text}
    </>
  )
}

export default Header