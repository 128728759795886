import React from 'react'
import Navigationbar from '../components/Navigationbar'
import { Container, Typography } from '@mui/material'
import Listing from '../pages/Listing'
import jsonLocalData from '../data/listing.json'


function Home() {
  return (
    <>
    <Navigationbar title={jsonLocalData.navbar.title}/>
        <Listing />
    </>
  )
}

export default Home