import React from 'react'
import { Container, Grid, Typography, Divider, List, ListItem, ListItemText } from '@mui/material'
import PostCard from '../components/PostCard'
import Footer from '../components/Footer'
import Header from '../components/Header'
import NoticePaper from '../components/NoticePaper'
import { PostModel } from '../models/PostModel'
import jsonLocalData from '../data/listing.json'
import ImageCarouselView from '../components/ImageCarouselView'
import FullScreenDialog from '../components/FullScreenDialog'

function Listing() {

  const [posts, setPosts] = React.useState([])
  const [informations, setInformations] = React.useState([])
  const [circulars, setCirculars] = React.useState([])
  const [dialogData, setDialogData] = React.useState(null)

  React.useEffect(() => {
    console.log('viewDidLoad()')

    let postModels = jsonLocalData.posts.map(p => {
      return new PostModel(p)
    })
    setPosts(postModels)

    let informationModels = jsonLocalData.informations.map(p => {
      return new PostModel(p)
    })

    setInformations(informationModels)

    let circularModels = jsonLocalData.circulars.map(p => {
      return new PostModel(p)
    })

    console.log("📦 From Locale => Post: ", postModels.length)

  }, [])

  const handleClick = (item) => {
    //TODO:
    console.log("Clicked...")
    setDialogData({state: true, title: item.title, imagePath: item.imagePath})
  }

  const onDismiss = () => {
    setDialogData(null)
  }

  return (
    <>
      {
        dialogData && <FullScreenDialog state={dialogData.state} title={dialogData.title} imagePath={dialogData.imagePath} onDismiss={onDismiss} />
      }
      <Container>
        <ImageCarouselView items={jsonLocalData.carousel} />

        <Typography color="textSecondary" variant="h4" sx={{ fontWeight: '700', fontFamily: 'Mukta' }}>
          संघ का इतिहास
        </Typography>

        <NoticePaper text={jsonLocalData.notice} />

        <div style={{ marginTop: '30px', marginBottom: '50px' }}>
          <Typography color="textSecondary" variant="h4" sx={{ fontWeight: '700', fontFamily: 'Mukta' }}>
            न्यूज एंड अपडेट
          </Typography>

          <Grid container spacing={1} sx={{ marginTop: '16px' }}>
            {
              posts && posts.map(item => (
                <Grid item xs={12} sm={6} md={4} lg={3} padding={2} key={item.postId} onClick={() => handleClick(item)} >
                  <PostCard post={item} />
                </Grid>
              ))
            }
          </Grid>
        </div>

        <div style={{ marginTop: '30px', marginBottom: '50px' }}>
          <Typography color="textSecondary" variant="h4" sx={{ fontWeight: '700', fontFamily: 'Mukta' }}>
            महत्वपूर्ण सूचनाएँ
          </Typography>

          <Grid container spacing={1} sx={{ marginTop: '16px' }}>
            {
              informations && informations.map(item => (
                <Grid item xs={12} sm={6} md={4} lg={3} padding={2} key={item.postId} onClick={() => handleClick(item)} >
                  <PostCard post={item} />
                </Grid>
              ))
            }
          </Grid>
        </div>
      </Container>
      <Footer />
    </>
  )
}

export default Listing