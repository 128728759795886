import React from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const NoticePaper = ({text}) => {
  return (
    <Paper sx={{ minHeight: 100, display: 'flex', alignItems: 'center', padding: 2, background: '#ecf0f1', borderRadius: 0, marginBottom: 2, border: '1px solid #95a5a6'}} elevation={0} >
      <Typography variant="body1" sx={{fontWeight: 500, fontFamily: 'Mukta', fontSize: '1.2rem', padding: 2}}>
        {text}
      </Typography>
    </Paper>
  );
};

export default NoticePaper;