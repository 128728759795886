import React from 'react';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Stack, Tooltip, Divider, Paper } from '@mui/material';
//import { makeStyles } from '@mui/styles';   

// const useStyles = makeStyles((theme) => ({
//     paper: {
//         margin: theme.spacing(1),
//         position: 'relative',
//         borderRadius: '0px',
//     },
//     media: {
//         height: 180,
//     },
// }));

function PostCard({ post }) {
    //const classes = useStyles();

    const titleStyle = {
        fontFamily: 'Mukta Bold',
        fontWeight: 600,
        fontSize: 18
    }

    const descriptionStyle = {
        fontFamily: 'Mukta Semibold',
        fontSize: 20,
        fontWeight: 800,
        color: '#2980b9'
    }

    const bodyStyle = {
        fontFamily: 'Mukta',
        fontSize: 16,
        fontWeight: 800,
    }

    const footerStyle = {
        fontFamily: 'Mukta',
        fontSize: 14,
        fontWeight: 500,
        background: '#fff',
        color: '#95a5a6'
    }

    return (
        <>
            <Paper elevation={1} sx={{ maxHeight: '350px' }}>
                {console.log(post.imagePath)}
                <CardMedia
                    component="img"
                    image={post.imagePath}
                    height="160"
                />
                <Tooltip title="This Tooltip" placement="left" arrow></Tooltip>
                <Stack padding={1}>
                    <Typography variant="subtitle1" color="textSecondary" sx={titleStyle}>
                        {post.title}
                    </Typography>
                    <Divider sx={{ width: '30px', margin: '8px 1px' }} align="left" />
                    <Typography variant="caption" sx={footerStyle} color="textSecondary">
                        {post.description}
                    </Typography>
                </Stack>
            </Paper>
        </>
    )
}

export default PostCard