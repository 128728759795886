import React from 'react'
import { Box, Container, Grid, Typography } from "@mui/material"
import jsonLocalData from '../data/listing.json'

function Footer() {
    return (
        <>
            <Box
                sx={{
                    width: "100%",
                    height: "auto",
                    backgroundColor: "#ecf0f1",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                }}
            >
                <Container maxWidth="lg">
                    <Grid container direction="column" alignItems="center">
                        <Grid item xs={12}>
                            <Typography color="black" sx={{fontSize: '14px'}}>
                                {jsonLocalData.footer.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography color="textSecondary" variant="caption">
                                {jsonLocalData.footer.subtitle}
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default Footer